*, *:before, *:after {
  background: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

a, a:visited {
  text-decoration: none;
}

a[target="_blank"]:after {
  content: " (" attr(href) ")";
  font-size: 90%;
}

abbr[title]:after {
  content: " (" attr(title) ")";
}

thead {
  display: table-header-group;
}

tr, img, figure, blockquote, li, .item, .f-row, .upcoming-dates, .files {
  page-break-inside: avoid;
}

@page {
  margin: 0.5cm;
}
p, h2, h3 {
  orphans: 3;
  widows: 3;
}

h1, h2, h3, h4 {
  page-break-after: avoid;
}

.hero-logo, .menu-btn, .f-pro, .f-newsletter, .cta, .hero p a, .pagination, .menu-wrapper, .filters .nav-2, .video-fig {
  display: none !important;
}

.hero {
  color: #242d27;
}
.hero text {
  fill: #242d27;
}
.hero figure {
  display: none;
}

.masonry .inner-grid {
  height: auto !important;
}
.masonry .item {
  position: relative !important;
  left: auto !important;
  top: auto !important;
}

#home_productions {
  float: left;
  width: 50%;
}

#home_news .section-title {
  margin-left: 50%;
  padding-left: 16px;
}

/*# sourceMappingURL=print.css.map */
